import React, { memo } from "react";

import * as S from "./social-icons.styles";

export const SocialLinks = ({ links }) => (
  <S.SocialList>
    {links.map(({ icon, link }, idx) => (
      <S.SocialItem key={`social-${idx}`}>
        <S.SocialLink href={link} target="_blank" rel="noopener noreferrer">
          <S.SocialIcon width={40} height={40} icon={icon} />
        </S.SocialLink>
      </S.SocialItem>
    ))}
  </S.SocialList>
);

SocialLinks.defaultProps = {
  links: []
};

export default memo(SocialLinks);
