import React, { useState } from "react";
import { RemoveScroll } from "react-remove-scroll";

import NormalizeCSS from "../styles/normalize";

import Header from "./header";
import Nav from "./nav";
import Footer from "./footer";
import { VideoModalProvider } from "../hooks/use-video-modal";

import BaseCSS from "../styles/global";
import { Helmet } from "react-helmet";

const Layout = ({ pageTitle, children, header, footer }) => {
  const [scroll, setScroll] = useState(true);
  return (
    <RemoveScroll enabled={!scroll}>
      <NormalizeCSS />
      <BaseCSS />

      <Helmet>
          <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '236426871809033');
          fbq('track', 'PageView');
          `}</script>
          <noscript>{`<img height="1" width="1" style={{display:"none"}} alt=""
          src="https://www.facebook.com/tr?id=236426871809033&ev=PageView&noscript=1"
          />`}</noscript>
      </Helmet>

      {header && (
        <Header pageTitle={pageTitle} nav={<Nav setScroll={setScroll} />} />
      )}
      <VideoModalProvider>
        <main>{children}</main>
      </VideoModalProvider>
      {footer && <Footer />}
    </RemoveScroll>
  );
};

Layout.defaultProps = {
  seo: {},
  header: true,
  footer: true,
};

export default Layout;
