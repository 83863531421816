import React from "react";
import SVGIcon from "./svg-icon";

const FileIcon = props => (
  <SVGIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path strokeLinejoin="round" d="M18 17h24v26H18z" />
      <path d="M22 32h16m-16 5h16M23 22h5v5h-5z" />
    </g>
  </SVGIcon>
);

FileIcon.defaultProps = {
  width: 60,
  height: 60,
  viewBox: "0 0 60 60"
};

export default FileIcon;
