import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import Image from "app/components/image";

import nfteLogo from "../images/nfte-logo.svg";

const NFTE_LOGO_QUERY = graphql`
  query {
    contentJson(slug: { eq: "global-site-data" }) {
      nfte {
        logo {
          childImageSharp {
            gatsbyImageData(width: 80, height: 80)
          }
          publicURL
        }
        url
      }
    }
  }
`;

const NFTELogo = ({ alt, className, url: is_link, ...props }) => {
  const data = useStaticQuery(NFTE_LOGO_QUERY);
  const gatsbyImage = _get(data, "contentJson.nfte.logo.childImageSharp");
  const image = _get(data, "contentJson.nfte.logo.publicURL") || nfteLogo;
  const url = _get(data, "contentJson.nfte.url");

  if (!is_link) {
    return (
      <>
        {gatsbyImage && (
          <Image
            image={gatsbyImage}
            alt={alt}
            className={className}
            {...props}
          />
        )}
        {!gatsbyImage && <img src={image} alt={alt} className={className} />}
      </>
    );
  }

  return (
    <a href={url} target={"_blank"} rel={"noopener noreferrer"}>
      {gatsbyImage && (
        <Image image={gatsbyImage} alt={alt} className={className} {...props} />
      )}
      {!gatsbyImage && <img src={image} alt={alt} className={className} />}
    </a>
  );
};

NFTELogo.defaultProps = {
  alt: "NFTE",
};

export default NFTELogo;

export const nfteLogoField = {
  label: "NFTE Logo",
  name: "nfte",
  widget: "object",
  fields: [
    {
      label: "Logo",
      name: "logo",
      widget: "file",
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
    },
  ],
};
