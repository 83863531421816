import styled from "@emotion/styled";
import { cleanList } from "../styles/shared";
import { rem } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import Icon from "./icons";

export const SocialList = styled.ul`
  ${cleanList};
  display: flex;
`;

export const SocialItem = styled.li`
  margin-left: ${rem(12)};

  &:first-of-type {
    margin-left: 0;
  }

  @media (${desktop}) {
    margin-left: ${rem(4)};
  }
`;

export const SocialLink = styled.a`
  display: block;
`;

export const SocialIcon = styled(Icon)`
  width: ${rem(40)};
  height: ${rem(40)};

  @media (${desktop}) {
    width: ${rem(30)};
    height: ${rem(30)};
  }
`;
