import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { fontSize, fontWeights, rem, colors } from "../styles/utils";
import { centerTxt, H6 } from "../styles/shared";
import { fadeIn } from "../styles/animations";

import { desktop } from "../styles/breakpoints";
import { Link } from "gatsby";
import Btn from "./btn";

const { white, purple } = colors;
export const navBrkpx = 780;

export const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const NavList = styled.ul`
  margin: auto auto;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const NavListM = styled(NavList)`
  flex-direction: column;
  text-align: center;
`;

export const NavLink = styled(Link)`
  display: inline-block;
  padding: 0 0 ${rem(4)};
  border-bottom: ${rem(3)} solid transparent;
  margin: 0 ${rem(12)} ${rem(-4)};
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  white-space: nowrap;

  &.active {
    border-color: ${purple};
  }
`;

export const NavLinkM = styled(NavLink)`
  ${fontSize(24, 40)};
  font-weight: ${fontWeights.medium};
  text-transform: none;
  margin: ${rem(15)} auto;
`;

export const NavCountDown = styled(Link)`
  display: flex;
  align-items: center;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.medium};
  margin: 0 ${rem(20)};
  justify-content: center;
`;

export const NavDaysNum = styled.span`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${rem(30)};
  height: ${rem(30)};
  border-radius: 100%;
  background-color: ${purple};
  color: ${white};
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
`;

export const NavLastDay = styled(NavDaysNum)`
  width: auto;
  padding-left: ${rem(15)};
  padding-right: ${rem(15)};
  border-radius: ${rem(30)};
`;

export const NavDaysTxt = styled.span`
  display: inline-block;
  ${fontSize(12, 20)};
  font-weight: ${fontWeights.black};
  margin: 0;
  margin-left: ${rem(10)};
  text-transform: uppercase;
  white-space: nowrap;
`;

export const NavBtn = styled(Btn)`
  min-width: 0;
  padding: ${rem(10)} ${rem(20)};
  margin: ${rem(10)} ${rem(20)};
  margin-left: auto;
  ${fontSize(12, 20)};
  white-space: nowrap;

  @media (${desktop}) {
    margin: ${rem(15)} ${rem(20)};
  }
`;

export const NavBtnM = styled(Btn)`
  margin: ${rem(40)} auto ${rem(80)};
`;

const mobileOpen = css`
  transform: translate3d(0, 0, 0);
`;

const mobileClosed = css`
  transform: translate3d(-200%, 0, 0);
`;

export const NavMFrame = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${white};
  animation-duration: 0.5s;
  transition: transform 0.5s ease-out;
  z-index: 10000;
  ${({ open }) => (open ? mobileOpen : mobileClosed)};
`;

export const NavMBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${rem(60)};
  height: 100%;
  background-color: ${white};
`;

export const closeIcon = css`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;
`;

export const NavMFt = styled.div`
  width: 100%;
  padding: ${rem(50)};
  background: linear-gradient(286.77deg, #b642a0 0%, #2f23ad 100%);
  margin-top: auto;
  color: ${white};
`;

export const NavMFtBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(260)};
  margin: 0 auto;
`;

export const NavMFtTxt = styled(H6)`
  ${centerTxt};
  margin: 0 auto;
  margin-top: ${rem(25)};
`;

export const navMfadeIn = css`
  ${fadeIn};
  animation-delay: 0.1s;
`;

export const navMFtLogo = css`
  max-width: ${rem(80)};
`;
