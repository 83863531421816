import React from "react";
import SVGIcon from "./svg-icon";

const PlayIcon = ({ fill, ...props }) => (
  <SVGIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="30" cy="30" r="30" fill={fill} />
      <path fill="currentColor" d="M39 30l-14 8V22z" />
    </g>
  </SVGIcon>
);

PlayIcon.defaultProps = {
  width: 60,
  height: 60,
  fill: "#000",
  viewBox: "0 0 60 60"
};

export default PlayIcon;
