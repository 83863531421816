import React from "react";
import SVGIcon from "./svg-icon";

const CheckIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.3 7.1l6.2 5.5L22.5 0 26 3.6 9.6 19.4 0 10.8z"
    />
  </SVGIcon>
);

CheckIcon.defaultProps = {
  width: 26,
  height: 20,
  viewBox: "0 0 26 20"
};

export default CheckIcon;
