import React from "react";
import SVGIcon from "./svg-icon";

const MenuIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27.5 22h12a1.5 1.5 0 0 1 0 3h-12a1.5 1.5 0 0 1 0-3zm-6 7h18a1.5 1.5 0 0 1 0 3h-18a1.5 1.5 0 0 1 0-3zm6 7h12a1.5 1.5 0 0 1 0 3h-12a1.5 1.5 0 0 1 0-3z"
    />
  </SVGIcon>
);

export default MenuIcon;
