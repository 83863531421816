import React from "react";
import _get from "lodash.get";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import SiteData from "app/content/_site-settings.json";
import logo from "../images/nfte-logo.jpg";

const SEO = ({ desc, lang, meta, image, title }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={`%s | ${_get(SiteData, "siteMeta.title")}`}
    meta={[
      {
        name: `description`,
        content: desc,
      },
      {
        property: `og:image`,
        content: image,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: desc,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      // {
      //   name: `twitter:creator`,
      //   content: author
      // },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: desc,
      },
    ]
      .concat(meta)
      .filter(({ content }) => !!content)}
  />
);

SEO.defaultProps = {
  lang: `en`,
  image: logo,
  meta: [],
  ..._get(SiteData, "seo"),
};

export default SEO;

export const seoFragment = graphql`
  fragment SEO on ContentJson {
    seo {
      title
      desc
    }
  }
`;

export const seoField = {
  label: "SEO",
  name: "seo",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Desc",
      name: "desc",
      widget: "text",
      required: false,
    },
  ],
};
