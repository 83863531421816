import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";

const { white, black } = colors;

const roundBtn = css`
  min-width: 0;
  padding: 0;
`;

const shortBtn = css`
  min-width: ${rem(160)};
`;

const thinBtn = css`
  min-width: 0;
  padding: ${rem(4)} ${rem(29)};
  ${fontSize(12, 16)};
`;

export const invertColors = css`
  background-color: ${white};
  color: ${black};

  &:focus,
  &:active,
  &:hover {
    color: ${black};
  }
`;

export const btn = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(15)};
  color: ${white};
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  background-color: ${black};
  min-width: ${rem(200)};
  text-transform: uppercase;
  border: 0;
  border-radius: ${rem(30)};
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    color: ${white};
  }
`;

export const Btn = styled.a`
  ${btn};
  ${({ short }) => short && shortBtn};
  ${({ thin }) => thin && thinBtn};
  ${({ round }) => round && roundBtn};
  ${({ invert }) => invert && invertColors};
`;

export const Link = styled.a`
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
`;

export const BtnCenterWrap = styled.div`
  margin: auto;
  overflow: hidden;
  text-align: center;
`;
