import React from "react";
import _get from "lodash.get";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import usePreview from "../cms/usePreview";

const Image = ({ alt, image, circle, ...props }) => {
  const gatsbyImage = getImage(image);
  const { getAsset, isPreview } = usePreview();

  if (gatsbyImage) {
    return <GatsbyImage image={gatsbyImage} alt={alt} {...props} />;
  }

  const src =
    isPreview && typeof image === "string"
      ? getAsset(image)
      : _get(image, "publicURL") || image;

  if (!src) {
    return null;
  }

  return <img src={src} alt={alt} {...props} />;
};

Image.defaultProps = {
  alt: "",
};

export default Image;
