import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { bodyTxt, cleanList, H6 } from "../styles/shared";
import { desktop, mobile } from "../styles/breakpoints";
import {
  fontSize,
  fontWeights,
  colors,
  rem,
  maxWidthpx,
} from "../styles/utils";

const { black, white, orange } = colors;

export const FtAddress = styled.address`
  display: block;
  ${bodyTxt};
  font-style: normal;
`;

const lineSpace = ({ gap }) => css`
  margin-bottom: ${gap ? rem(20) : rem(10)};
`;

export const FtAddressLine = styled.span`
  display: block;
  ${bodyTxt};
  ${lineSpace};
`;

export const ftTxt = css`
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
`;

export const ftLegal = css`
  ${fontSize(14, 20)};
  font-style: normal;
  font-weight: ${fontWeights.book};

  @media (${mobile}) {
    order: 2;
  }
`;

export const FtHighlight = styled(H6)`
  ${fontSize(18, 28)};
  color: ${orange};
  margin: 0;
`;

export const ftLegalLinks = css`
  ${cleanList};
  display: flex;

  @media (${mobile}) {
    order: 0;
    margin-bottom: ${rem(30)};
  }
`;

export const ftSpaceBot = css`
  display: block;
  margin-top: 0;
  margin-bottom: ${rem(20)};
`;

export const Ft = styled.div`
  background: ${black};
  color: ${white};
  padding: ${rem(60)} 0 ${rem(30)};
`;

const colWidth = ({ small, large }) => css`
  @media (${desktop}) {
    ${small && `width:${(260 / maxWidthpx) * 100}%;`};
    ${large && `width:${(460 / maxWidthpx) * 100}%;`};
  }
`;

const colGutter = ({ zeroMargin, gutterLeft, pushLeft }) => css`
  ${zeroMargin && `margin: 0`};

  @media (${desktop}) {
    ${gutterLeft && `margin-left:${rem(18)}`};
    ${pushLeft && `margin-left: auto;`}
  }
`;

export const FtCol = styled.div`
  ${colWidth};
  ${colGutter};

  @media (${mobile}) {
    margin-bottom: ${rem(40)};
  }
`;

const botRow = css`
  display: flex;
  flex-direction: column;

  @media (${desktop}) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const rowStyles = ({ bot }) => css`
  @media (${desktop}) {
    display: flex;
  }
  ${bot && botRow};
`;

export const FtRow = styled.div`
  ${rowStyles};
`;

export const logo = css`
  display: block;
  margin-bottom: ${rem(30)};
`;

export const genLogo = css`
  width: ${rem(80)};
  height: ${rem(80)};
  margin: ${rem(30)} 0;

  @media (${desktop}) {
    width: ${rem(90)};
    height: ${rem(90)};
  }
`;

export const socialCol = css`
  ${cleanList};

  @media (${mobile}) {
    margin-top: ${rem(40)};
    margin-bottom: ${rem(40)};
  }
`;
