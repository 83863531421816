import React from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";

import { rem, colors } from "../styles/utils";

export const parseVideoId = (url) => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  if (typeof url !== "string") {
    return;
  }

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );

  return RegExp.$6;
};

const videoModalStyles = /* CSS */ `
  .modal-video {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .modal-video-body {
    max-width: ${rem(960)};
  }
  .modal-video-inner {
    padding-left: ${rem(30)};
    padding-right: ${rem(30)};
  }

  @media (min-width: ${rem(1160 + 60)}) {
    .modal-video-inner {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .modal-video-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto;
    bottom: ${rem(-30)};
    left: 50%;
    right: auto;
    padding: 0;
    width: ${rem(60)};
    height: ${rem(60)};
    transform: translate3d(-50%, 100%, 0);
    background: ${colors.white};
    border-radius: 100%;
    cursor: pointer;
  }

  .modal-video-close-btn::before,
  .modal-video-close-btn::after {
    margin-top: 0;
    width: 50%;
    top: 50%;
    left: 50%;
    background: ${colors.black};
  }

  .modal-video-close-btn::before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }

  .modal-video-close-btn::after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
`;

const VideoModal = ({ videoUrl, ...props }) => {
  if (typeof window === "undefined") {
    return null;
  }

  return (
    <>
      <style
        data-name="modal-video"
        dangerouslySetInnerHTML={{ __html: videoModalStyles }}
      />
      <ModalVideo
        channel={
          videoUrl && videoUrl.indexOf("vimeo.com") !== -1 ? "vimeo" : "youtube"
        }
        videoId={parseVideoId(videoUrl)}
        {...props}
      />
    </>
  );
};

export default VideoModal;
