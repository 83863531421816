import React, { useContext } from "react";

const PreviewContext = React.createContext({});

export const PreviewProvider = ({ children, getAsset }) => (
  <PreviewContext.Provider value={{ isPreview: true, getAsset }}>
    {children}
  </PreviewContext.Provider>
);

const usePreview = () => {
  const context = useContext(PreviewContext);

  if (!context) {
    return { isPreview: false };
  }

  return context;
};

export default usePreview;
