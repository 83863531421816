import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";
import { Link } from "gatsby";

import { parseVideoId } from "app/components/video-modal";
import useVideoModal from "app/hooks/use-video-modal";

import * as S from "app/components/btn.styles";

export const blur = (e) => {
  e.preventDefault();
  e.target.blur();
};

const handleOnMouseDown = ({ onMouseDown }) => ({
  onMouseDown: (e) => {
    blur(e);
    typeof onMouseDown === "function" && onMouseDown(e);
  },
});

const handleOnClick = ({ onClick, track_action, track_cat, to, url }) => ({
  onClick: (e) => {
    typeof onClick === "function" && onClick(e);

    if (window === "undefined" || !window.gtag || !track_action) {
      return;
    }

    window.gtag("event", track_action, {
      event_category: track_cat || "cta",
      event_label: to || url,
    });
  },
});

const handleTargetBlank = ({ rel, new_window, target }) => {
  const linkTarget = new_window ? "_blank" : target;

  return {
    rel: linkTarget === "_blank" ? `${rel} noopener noreferrer` : rel,
    target: linkTarget,
  };
};

const relativize = (url) => {
  const origin =
    typeof window !== "undefined" && _get(window, "location.origin");
  return origin && typeof url === "string" ? url.replace(origin, "") : url;
};

export const handleGatsbyLink = (props) => {
  const link =
    _get(props, "to") ||
    _get(props, "uri") ||
    _get(props, "file") ||
    _get(props, "file.publicURL") ||
    relativize(_get(props, "href") || _get(props, "url"));

  const download = !!_get(props, "file.publicURL") || !!_get(props, "file");

  if (!link) {
    return {
      as: "button",
      "data-nolink": true,
    };
  }

  if (/^https?:\/\/|mailto:|tel:/i.test(link)) {
    return {
      href: link,
      download: download ? download : undefined,
    };
  }

  if (/^#/i.test(link)) {
    return {
      to: link,
      as: Link,
    };
  }

  return {
    to: `/${link}`.replace(/^\/\//, "/"),
    as: Link,
  };
};

const Btn = ({ children, label, variant, ...props }) => {
  const { uri, url, css, localFile, new_window, ...linkProps } = {
    ...props,
    ...handleGatsbyLink(props),
    ...handleOnMouseDown(props),
    ...handleTargetBlank(props),
  };

  const BtnBase = variant === "link" ? S.Link : S.Btn;

  return (
    <BtnBase {...linkProps} {...handleOnClick(linkProps)}>
      {children || label}
    </BtnBase>
  );
};

Btn.defaultProps = {
  variant: "button",
};

export const VideoBtn = ({ url, ...rest }) => {
  const { openModal } = useVideoModal();
  return <Btn onClick={() => openModal(url)} {...rest} />;
};

const WrappedButton = ({ center, ...props }) => {
  const Wrap = center ? S.BtnCenterWrap : React.Fragment;
  const Button = (parseVideoId(_get(props, "url")) && VideoBtn) || Btn;

  return (
    <Wrap>
      <Button {...props} />
    </Wrap>
  );
};

export default WrappedButton;

export const btnFragment = graphql`
  fragment ButtonFields on ContentJsonButton {
    label
    name
    file
    url
    new_window
  }

  # fragment Button on ContentJson {
  #   btn {
  #     ...ButtonFields
  #   }
  # }
`;

export const btnField = {
  label: "CTA",
  name: "btn",
  widget: "object",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
      required: false,
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
      required: false,
    },
    {
      label: "New Window",
      name: "new_window",
      widget: "boolean",
      required: false,
    },
  ],
};
