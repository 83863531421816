import React from "react";
import "circular-std";
import { css, Global } from "@emotion/react";

import { baseFont, colors } from "./utils";

const { black } = colors;

const font = css`
  html {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    font-family: ${baseFont};
    color: ${black};
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

const links = css`
  a,
  a:active,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
  }
`;

const GlobalStyles = () => (
  <>
    <Global
      styles={css`
        ${font};
        ${links};
      `}
    />
  </>
);

export default GlobalStyles;
