import React from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";

import { useNavData } from "app/hooks/use-nav-data";

import GenGlobalLogo from "app/components/gen-global-logo";
import SocialIcons from "app/components/social-icons";
import NFTELogo from "app/components/nfte-logo";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/footer.styles";

const S = {
  ...stylesShared,
  ...styles,
};

const FooterAbout = ({ title, text }) => (
  <>
    <h2 css={[S.ftTxt, S.ftSpaceBot]}>{title}</h2>
    <S.FtHighlight as="p">{text}</S.FtHighlight>
  </>
);

const FooterNav = ({ links }) => (
  <ul css={S.cleanList}>
    {links.map(({ label, url }, idx) => (
      <li css={S.ftSpaceBot} key={idx}>
        <Link css={S.ftTxt} to={url}>
          {label}
        </Link>
      </li>
    ))}
  </ul>
);

const FooterContact = ({ address, phone, email, title, url }) => (
  <section>
    <h2 css={[S.ftTxt, S.ftSpaceBot]}>
      {title && url && (
        <Link to={url} css={[S.ftTxt, S.ftSpaceBot]}>
          {title}
        </Link>
      )}
      {title && !url && title}
    </h2>
    <S.FtAddress>
      {address && (
        <S.FtAddressLine gap>
          <ReactMarkdown
            disallowedElements={["paragraph"]}
            unwrapDisallowed
            children={address}
          />
        </S.FtAddressLine>
      )}
      {phone && (
        <S.FtAddressLine
          css={S.orange}
          as="a"
          href={`tel:${phone.replace(/ /g, "-")}`}
          rel="nofollow"
        >
          {phone}
        </S.FtAddressLine>
      )}
      {email && (
        <a css={S.orange} href={`mailto:${email}`} rel="nofollow">
          {email}
        </a>
      )}
    </S.FtAddress>
  </section>
);

FooterContact.defaultProps = {
  title: "Contact Us",
  url: "/contact-us",
};

const Footer = ({
  about,
  contact,
  copyright,
  genglobal,
  navLinks = [],
  legalLinks = [],
  socialLinks = [],
}) => (
  <S.Ft as="footer">
    <S.Container>
      <S.FtRow>
        <NFTELogo css={S.logo} />
      </S.FtRow>
      <S.FtRow>
        <S.FtCol large>
          <FooterAbout {...about} genglobal={genglobal} />
          <GenGlobalLogo {...genglobal} />
        </S.FtCol>

        <S.FtCol pushLeft small>
          <FooterNav links={navLinks} />
        </S.FtCol>

        <S.FtCol gutterLeft small as="section">
          <FooterContact {...contact} />
        </S.FtCol>
      </S.FtRow>
      <S.FtRow bot>
        <S.FtCol css={S.ftLegal} zeroMargin large as="p">
          ©{new Date().getFullYear()} {copyright}
        </S.FtCol>

        <S.FtCol css={S.ftLegalLinks} pushLeft small as="ul">
          {legalLinks.map(({ label, url }, idx) => (
            <li key={idx}>
              {idx !== 0 && `\u00a0`}
              <Link css={S.ftTxt} to={url}>
                {label}
              </Link>
              {idx === 0 && `\u00a0|`}
            </li>
          ))}
        </S.FtCol>

        <S.FtCol css={S.socialCol} gutterLeft small>
          <SocialIcons links={socialLinks} />
        </S.FtCol>
      </S.FtRow>
    </S.Container>
  </S.Ft>
);

const FooterWithData = () => {
  const { footer } = useNavData();
  return <Footer {...footer} />;
};

export default FooterWithData;

export const footerField = {
  label: "Footer",
  name: "footer",
  widget: "object",
  fields: [
    {
      label: "About WSI",
      name: "about",
      widget: "object",
      fields: [
        {
          label: "Title",
          name: "title",
          widget: "string",
        },
        {
          label: "Text",
          name: "text",
          widget: "text",
        },
      ],
    },
    {
      label: "Contact",
      name: "contact",
      widget: "object",
      fields: [
        {
          label: "Title",
          name: "title",
          widget: "string",
        },
        {
          label: "Url",
          name: "url",
          widget: "string",
        },
        {
          label: "Phone",
          name: "phone",
          widget: "string",
        },
        {
          label: "Email",
          name: "email",
          widget: "string",
        },
        {
          label: "Address",
          name: "address",
          widget: "text",
        },
      ],
    },
    {
      label: "Footer Links",
      name: "navLinks",
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          widget: "string",
        },
        {
          label: "Url",
          name: "url",
          widget: "string",
        },
        {
          label: "New Window?",
          name: "new_window",
          widget: "boolean",
          default: false,
          required: false,
        },
      ],
    },
    {
      label: "Legal Links",
      name: "legalLinks",
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          widget: "string",
        },
        {
          label: "Url",
          name: "url",
          widget: "string",
        },
        {
          label: "New Window?",
          name: "new_window",
          widget: "boolean",
          default: false,
          required: false,
        },
      ],
    },
    {
      label: "Social Links",
      name: "socialLinks",
      widget: "list",
      fields: [
        {
          label: "Icon",
          name: "icon",
          widget: "select",
          options: ["twitter", "linkedin", "facebook", "instagram"],
        },
        {
          label: "Url",
          name: "link",
          widget: "string",
        },
      ],
    },
    {
      label: "Copyright",
      name: "copyright",
      widget: "string",
    },
  ],
};
