import React from "react";
import SVGIcon from "./svg-icon";

const ArrowIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M.5 13.6L1.8 15l8.7-7.4L1.8.3.5 1.8l6.9 5.9z"
    />
  </SVGIcon>
);

ArrowIcon.defaultProps = {
  width: 11,
  height: 16,
  viewBox: "0 0 11 16"
};

export default ArrowIcon;
