import React, { memo } from "react";
import ArrowIcon from "./arrow-icon";
import CheckIcon from "./check-icon";
import CloseIcon from "./close-icon";
import FacebookIcon from "./facebook-icon";
import FileIcon from "./file-icon";
import InstagramIcon from "./instagram-icon";
import LinkedInIcon from "./linkedin-icon";
import MenuIcon from "./menu-icon";
import PlayIcon from "./play-icon";
import ShareIcon from "./share-icon";
import ShuffleIcon from "./shuffle-icon";
import StarIcon from "./star-icon";
import TwitterIcon from "./twitter-icon";

const Icon = ({ icon, ...props }) => (
  <>
    {icon === "facebook" && <FacebookIcon {...props} />}
    {icon === "instagram" && <InstagramIcon {...props} />}
    {icon === "twitter" && <TwitterIcon {...props} />}
    {icon === "linkedin" && <LinkedInIcon {...props} />}
  </>
);

export default memo(Icon);

export {
  ArrowIcon,
  CheckIcon,
  CloseIcon,
  FacebookIcon,
  FileIcon,
  InstagramIcon,
  TwitterIcon,
  PlayIcon,
  MenuIcon,
  ShareIcon,
  ShuffleIcon,
  StarIcon
};
