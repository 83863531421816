import React from "react";
import SVGIcon from "./svg-icon";

const LinkedInIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M44 44h-5.8v-9.2c0-2.2 0-5-3.1-5s-3.6 2.4-3.6 4.8v9.5h-6V25h5.8v2.6c.8-1.5 2.8-3 5.7-3 6 0 7 3.9 7 9zM19 22.5a3.4 3.4 0 1 1 0-6.9 3.4 3.4 0 0 1 0 6.9zm-3 21.7h6V25h-6zM47 10H13a3 3 0 0 0-3 2.9V47a3 3 0 0 0 3 2.9h34a3 3 0 0 0 3-2.9V13a3 3 0 0 0-3-2.9z"
    />
  </SVGIcon>
);

export default LinkedInIcon;
