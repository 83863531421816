import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import Image from "app/components/image";

import * as S from "app/components/footer.styles";

const GEN_GLOBAL_QUERY = graphql`
  query {
    contentJson(slug: { eq: "global-site-data" }) {
      genglobal {
        logo {
          childImageSharp {
            gatsbyImageData(width: 80, height: 80)
          }
          publicURL
        }
        url
        new_window
      }
    }
  }
`;

const GenGlobalLogo = () => {
  const data = useStaticQuery(GEN_GLOBAL_QUERY);
  const image = _get(data, "contentJson.genglobal.logo");
  const url = _get(data, "contentJson.genglobal.url");
  const new_window = _get(data, "contentJson.genglobal.new_window");

  if (!(_get(image, "childImageSharp") || _get(image, "publicURL")) || !url) {
    return null;
  }

  return (
    <a
      href={url}
      target={new_window && "_blank"}
      rel={new_window && "noopener noreferrer"}
    >
      <Image
        css={S.genLogo}
        image={image}
        objectFit="cover"
        objectPosition="50% 50%"
        alt="Global Entrepreneurship Network"
      />
    </a>
  );
};

export default GenGlobalLogo;

export const genGlobalField = {
  label: "Gen Global Logo",
  name: "genglobal",
  widget: "object",
  fields: [
    {
      label: "Logo",
      name: "logo",
      widget: "file",
      required: false,
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
      required: false,
    },
    {
      label: "New Window?",
      name: "new_window",
      widget: "boolean",
      default: false,
      required: false,
    },
  ],
};
