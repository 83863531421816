import React from "react";
import SVGIcon from "./svg-icon";

const MenuIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.9 30l6.7 6.8a1.3 1.3 0 1 1-1.8 1.8L30 32l-6.8 6.7a1.3 1.3 0 1 1-1.8-1.8L28 30l-6.7-6.8a1.3 1.3 0 1 1 1.8-1.8L30 28l6.8-6.7a1.3 1.3 0 1 1 1.8 1.8L32 30z"
    />
  </SVGIcon>
);

export default MenuIcon;
