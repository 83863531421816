import React from "react";
import SVGIcon from "./svg-icon";

const ShareIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.291 34.116c-.647 0-1.235.235-1.764.588l-7.705-4.411c0-.118.059-.235.059-.295 0-.058 0-.235-.059-.294l7.705-4.41c.47.353 1.117.588 1.764.588a2.912 2.912 0 0 0 2.941-2.941A2.912 2.912 0 0 0 35.292 20a2.912 2.912 0 0 0-2.942 2.94c0 .119 0 .177.059.295l-7.704 4.412a2.89 2.89 0 0 0-1.764-.587A2.912 2.912 0 0 0 20 30a2.912 2.912 0 0 0 2.94 2.942c.648 0 1.236-.235 1.765-.588l7.705 4.41c0 .119-.058.177-.058.295A2.912 2.912 0 0 0 35.292 40a2.912 2.912 0 0 0 2.941-2.94 2.915 2.915 0 0 0-2.942-2.944z"
    />
  </SVGIcon>
);

ShareIcon.defaultProps = {
  width: 60,
  height: 60,
  viewBox: "0 0 60 60"
};

export default ShareIcon;
