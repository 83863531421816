import { css } from "@emotion/react";

export const baseFont = "CircularStd, sans-serif";

export const fontWeights = {
  black: 800,
  bold: 600,
  medium: 500,
  book: 400,
};

export const percent = (n) => `${Math.floor(n * 100)}%`;

export const rem = (px) => `${px / 16}rem`;

export const fontSize = (px, lineHeight) => css`
  font-size: ${rem(px)};
  line-height: ${(lineHeight && lineHeight / px) || "1.2"};
`;

// export const mobileBreak = css => {
//   return css`
//     @media (min-width: ${rem(650)}) {
//       ${css}
//     }
//   `;
// };

export const maxWidthpx = 1160;
export const maxWidth = rem(1160);

export const colors = {
  white: "#fff",
  black: "#000",
  lightGray: "#BBBBBB",
  darkGray: "#555555",
  red: "#C31E33",
  mint: "#EDFFFC",
  aqua: "#6DFFEC",
  green: "#3EB04A",
  orange: "#FF5C39",
  orangeLight: "#F99D25",
  blue: "#00AED9",
  purple: "#4C12A1",
};

export const sdgColors = [
  "#EB1C2D",
  "#D3A029",
  "#269B48",
  "#C31E33",
  "#EF402C",
  "#00AED9",
  "#FDB713",
  "#8F1838",
  "#F36D25",
  "#E11384",
  "#F99D25",
  "#CF8D2A",
  "#47773E",
  "#017DBC",
  "#3EB04A",
  "#02558B",
  "#183667",
];

export const hexToRGBA = (hex, a = 1) => {
  const value = parseInt(hex, 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return `rgba(${r},${g},${b},${a})`;
};

export const debugCenterLine = css`
  &::after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    display: block;
    width: 1px;
    background: red;
    z-index: 20000;
  }
`;
