import React from "react";
import _get from "lodash.get";

import NFTELogo from "./nfte-logo";
import SiteSettings from "app/content/_site-settings.json";

import * as S from "./header.styles";

const Header = ({ nav, pageTitle }) => (
  <S.HGSpacer>
    <S.HGBox>
      <S.HGTitleBlock to="/">
        <S.HGLogoBox>
          <NFTELogo url={false} css={S.headerLogo} />
        </S.HGLogoBox>
        <S.HGTitle>
          {!pageTitle && (
            <S.HGTitleFull>
              {_get(SiteSettings, "siteMeta.title")}
            </S.HGTitleFull>
          )}
          {pageTitle && (
            <S.HGTitleFull>
              <S.HGSiteName>
                {_get(SiteSettings, "siteMeta.shortName")}&nbsp;
              </S.HGSiteName>
              <S.HGPageTitle>{pageTitle}</S.HGPageTitle>
            </S.HGTitleFull>
          )}
          <S.HGTitleShort>
            {_get(SiteSettings, "siteMeta.shortName")}
          </S.HGTitleShort>
        </S.HGTitle>
      </S.HGTitleBlock>
      {nav}
    </S.HGBox>
  </S.HGSpacer>
);

export default Header;
