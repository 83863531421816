import React, { useContext, useState } from "react";
import VideoModal, { parseVideoId } from "app/components/video-modal";

const VideoModalContext = React.createContext({});

export const isVideoUrl = (url) => !!parseVideoId(url);

export const VideoModalProvider = ({ children }) => {
  const [videoUrl, setVideoUrl] = useState();
  const [isOpen, setOpen] = useState(false);

  const openModal = (url) => {
    if (!url) {
      return;
    }

    setVideoUrl(url);
    setOpen(true);
  };

  const closeModal = () => {
    setVideoUrl(undefined);
    setOpen(false);
  };

  return (
    <VideoModalContext.Provider
      value={{ videoUrl, setVideoUrl, isOpen, openModal, closeModal }}
    >
      {children}
      <VideoModal videoUrl={videoUrl} isOpen={isOpen} onClose={closeModal} />
    </VideoModalContext.Provider>
  );
};

const useVideoModal = () => {
  const context = useContext(VideoModalContext);
  return context;
};

export default useVideoModal;
