import { css, keyframes } from "@emotion/react";

const baseAnimate = css`
  animation-fill-mode: both;
  animation-duration: 0.5s;
`;

const slideInLeftAnim = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInAnim = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const rotateAnim = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
`;

const slideInRightAnim = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const navIconPrevAnim = keyframes`
  0% {
    transform: translate3d(-50vw, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

const navIconNextAnim = keyframes`
  0% {
    transform: translate3d(50vw, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

const circleAnim = keyframes`
  from {
    transform: rotate(0deg)
               translate(5%)
               rotate(0deg) scale(1);
  }

  to {
    transform: rotate(360deg)
               translate(5%) 
               rotate(-360deg) scale(0.8);
  }
`;

const fadeZoomOutAnim = keyframes`
  from {
    transform: scale(1, 1);
    opacity: 1;
  }

  to {
    transform: scale(0, 0);
    opacity: 0;
  }
`;

export const moveInCircle = css`
  animation: ${circleAnim} 5s infinite linear;
  animation-direction: alternate;
`;

export const slideInLeft = css`
  ${baseAnimate};
  animation-name: ${slideInLeftAnim};
`;

export const fadeIn = css`
  ${baseAnimate};
  animation-name: ${fadeInAnim};
`;

export const fadeZoomOut = css`
  ${baseAnimate};
  animation-name: ${fadeZoomOutAnim};
`;

export const rotateFull = css`
  ${baseAnimate};
  animation-name: ${rotateAnim};
`;

export const slideInRight = css`
  ${baseAnimate};
  animation-name: ${slideInRightAnim};
`;

export const navIconPrev = css`
  ${baseAnimate};
  opacity: 0;
  animation-name: ${navIconPrevAnim};

  h3,
  p {
    ${baseAnimate};
    animation-delay: 0.1s;
    animation-name: ${fadeInAnim};
  }
`;

export const navIconNext = css`
  ${baseAnimate};
  opacity: 0;
  animation-name: ${navIconNextAnim};
  animation-timing-function: ease-in;

  h3,
  p {
    ${baseAnimate};
    animation-delay: 0.1s;
    animation-name: ${fadeInAnim};
  }
`;
