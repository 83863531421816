import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights } from "../styles/utils";
import { navBrkpx } from "./nav.styles";
// import { desktop } from "../styles/breakpoints";
// import { Container } from "../styles/shared";

import { Link } from "gatsby";

export const heightMpx = 60;
export const heightpx = 70;

export const HGSpacer = styled.div`
  height: ${rem(heightMpx)};

  @media (min-width: ${rem(navBrkpx)}) {
    height: ${rem(heightpx)};
  }
`;

export const achorLinkOffset = css`
  top: ${rem(heightMpx * -1)};

  @media (min-width: ${rem(navBrkpx)}) {
    height: ${rem(heightpx * -1)};
  }
`;

export const HGBox = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: stretch;
  z-index: 1000;
  background-color: white;
`;

export const HGTitleBlock = styled(Link)`
  display: flex;
  align-items: center;
  width: calc(100% - ${rem(600)});
`;

export const HGTitle = styled.h1`
  ${fontSize(20, 20)};
  padding: 0;
  padding-top: ${rem(4)};
  height: ${rem(23)};
  overflow: visible;
  margin: 0 ${rem(10)};
  width: calc(100% - ${rem(120)}); /* logo box width */
`;

export const HGLogoBox = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: flex-end;
  width: ${rem(100)};
  padding: 0 ${rem(5)};
  height: 100%;
  overflow: hidden;
  z-index: 2;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: ${rem(100)};
    height: ${rem(200)};
    border-bottom-right-radius: ${rem(200)};
    border-top-right-radius: ${rem(200)};
    transform: translateY(-50%);
    background: black;
    z-index: -1;
  }
`;

export const headerLogo = css`
  width: ${rem(64)};
`;

export const HGTitleFull = styled.span`
  display: flex;
  height: ${rem(24)};

  @media (max-width: ${rem(1012)}) {
    display: none;
  }
`;

export const HGTitleShort = styled.span`
  display: none;

  @media (max-width: ${rem(1012)}) {
    display: inline;
  }
`;

export const HGSiteName = styled.span`
  ${fontSize(20, 20)};
  font-weight: ${fontWeights.bold};
`;

export const HGPageTitle = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${fontSize(20, 20)};
  font-weight: ${fontWeights.book};
`;
