import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import useWindowWidth from "../hooks/use-window-width";
import usePortal from "react-useportal";
import { useNavData } from "../hooks/use-nav-data";

import NFTELogo from "./nfte-logo";
import * as S from "./nav.styles";

import { MenuIcon, CloseIcon } from "./icons";

import SiteData from "app/content/_site-globals.json";

const { navBrkpx } = S;

const NavButton = ({ label, url, ...props }) => (
  <S.NavBtn as="a" href={url} {...props}>
    {label}
  </S.NavBtn>
);

const LoginButton = (props) => {
  const { loginBtn } = useNavData();
  return <NavButton {...loginBtn} {...props} />;
};

const NavPanel = ({ mobileNavText, links, closeMenu, open, loginBtn }) => (
  <S.NavMFrame open={open} data-open={open}>
    <S.NavMBox as="section">
      <div>
        <CloseIcon css={S.closeIcon} onClick={closeMenu} />
        <S.NavListM mobile css={S.navMfadeIn}>
          {links.map(({ label, url }, idx) => (
            <li key={idx}>
              <S.NavLinkM activeClassName="active" to={url}>
                {label}
              </S.NavLinkM>
            </li>
          ))}
        </S.NavListM>
        {loginBtn && (
          <S.NavBtnM
            center
            as="a"
            href={loginBtn.url}
            new_window={loginBtn.new_window}
            css={S.navMfadeIn}
          >
            {loginBtn.label}
          </S.NavBtnM>
        )}
      </div>
      <S.NavMFt as="footer" css={S.navMfadeIn}>
        <S.NavMFtBox>
          <NFTELogo css={S.navMFtLogo} />
          <S.NavMFtTxt as="p">{mobileNavText}</S.NavMFtTxt>
        </S.NavMFtBox>
      </S.NavMFt>
    </S.NavMBox>
  </S.NavMFrame>
);

const HamNav = ({ setScroll, ...props }) => {
  const [open, setOpen] = useState(false);
  const { Portal } = usePortal({
    closeOnOutsideClick: false,
  });

  const openMenu = () => {
    setOpen(true);
    setScroll(false);
  };

  const closeMenu = () => {
    setOpen(false);
    setScroll(true);
  };

  return (
    <>
      <LoginButton />
      <MenuIcon onClick={openMenu} />

      {typeof document !== `undefined` && (
        <Portal>
          <NavPanel open={open} closeMenu={closeMenu} {...props} />
        </Portal>
      )}
    </>
  );
};

const NavBar = ({ links }) => (
  <>
    <S.NavList>
      {links.map(({ label, url }, idx) => (
        <li key={idx}>
          <S.NavLink activeClassName="active" to={url}>
            {label}
          </S.NavLink>
        </li>
      ))}
    </S.NavList>
    <LoginButton />
  </>
);

NavBar.defaultProps = {
  links: [],
};

const Nav = ({ setScroll, ...props }) => {
  const vpWidth = useWindowWidth();
  const fullNav = vpWidth > navBrkpx;

  useEffect(() => {
    fullNav && setScroll(true);
  }, [fullNav, setScroll]);

  return (
    <S.Nav>
      {fullNav && <NavBar setScroll={setScroll} {...props} />}
      {!fullNav && <HamNav setScroll={setScroll} {...props} />}
    </S.Nav>
  );
};

Nav.defaultProps = {
  links: _get(SiteData, "links"),
};

export default Nav;

export const loginBtnField = {
  label: "Top Nav Button",
  name: "loginBtn",
  widget: "object",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
    },
    {
      label: "New Window?",
      name: "new_window",
      widget: "boolean",
      default: false,
      required: false,
    },
  ],
};

export const navLinksField = {
  label: "Top Nav Links",
  name: "links",
  widget: "list",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
    },
    {
      label: "New Window?",
      name: "new_window",
      widget: "boolean",
      default: false,
      required: false,
    },
  ],
};

export const mobileNavTextField = {
  label: "Mobile Nav Text",
  name: "mobileNavText",
  widget: "string",
};
