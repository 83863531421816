import React from "react";
import SVGIcon from "./svg-icon";

const ShuffleIcon = props => (
  <SVGIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0a15 15 0 0 1 12.5 6.7V2.5H30v8.8h-8.8V8.7h4.6A12.5 12.5 0 1 0 15 27.6V30a15 15 0 0 1 0-30z"
    />
  </SVGIcon>
);

ShuffleIcon.defaultProps = {
  width: 30,
  height: 30,
  viewBox: "0 0 30 30"
};

export default ShuffleIcon;
