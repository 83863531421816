import React from "react";
import SVGIcon from "./svg-icon";

import { colors } from "../../styles/utils";

const StarIcon = ({ fill, ...props }) => (
  <SVGIcon {...props}>
    <g fill={fill} fillRule="evenodd">
      <circle cx="30" cy="30" r="24" opacity="0.06" />
      <path d="M29.98 20a.452.452 0 00-.414.297l-2.14 6.263-6.97-.013a.454.454 0 00-.434.296.417.417 0 00.166.481l5.648 3.857-2.168 6.257a.415.415 0 00.165.48c.159.11.375.11.534 0L30 34.038l5.634 3.88c.159.11.375.11.534 0a.415.415 0 00.165-.48l-2.168-6.257 5.648-3.857a.417.417 0 00.166-.48.453.453 0 00-.434-.297l-6.97.014-2.142-6.263v-.001A.455.455 0 0029.98 20z" />
    </g>
  </SVGIcon>
);

StarIcon.defaultProps = {
  fill: colors.purple,
  width: 60,
  height: 60,
  viewBox: "0 0 60 60"
};

export default StarIcon;
